import { Modal, QRCode } from "antd";
import React, { useState } from "react";
import ChooseRegenAgExp from "../../common/Tabs/chooseRegenAgExp";
import "./regenUEI.css";

const RegenUEIQr = () => {
  return (
    <div>
      <ChooseRegenAgExp />
    </div>
  );
};

export default RegenUEIQr;
