import React, { useState } from "react";
import OndcFlow from "../../common/Tabs/ondcFlow";
import "./ONDC.css";

const ONDCIframe = () => {
  return (
    <div>
      <OndcFlow />
    </div>
  );
};

export default ONDCIframe;
